<template>
  <div>
    <div class="alert alert-primary d-flex my-3" role="alert" v-if="!customRoles">
      <div class="mr-2">
        <i class="bi bi-info-circle-fill mr-1"></i>
      </div>
      <div>
        <p class="mb-1">
          Additional user roles and project permissions can be defined and assigned to individuals within your organization. 
        </p>
        <p>
          This allows for precise control over which users are authorized to make specific changes to GIRI, manage projects, and modify instructions.
        </p>
        <p class="mb-0">
          If you see this message, the roles and permissions feature is disabled for your organization. In case you are interested in that functionality, please 
          <a href="https://www.ar-giri.com/start" target="_blank">
            schedule an appointment with our service team!
          </a>
        </p>
      </div>
    </div>
    <div class="d-flex">
      <h2 class="title my-3">Configure Roles</h2>
    </div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <b-input-group class="w-50">
        <b-form-input type="search" placeholder="Search by permission" v-model="search" />
        <b-input-group-append>
          <b-input-group-text>
            <i class="bi bi-search"></i>
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <b-button style="background-color: #0093FF;" @click="showCreateRoleModal = true" :disabled="!canEdit">Create new role</b-button>
    </div>

    <b-alert :show="dismissCountDown" dismissible variant="success" @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged">
      {{ alertMsgContent }}
    </b-alert>
    <b-alert :show="dismissCountDown2" dismissible variant="success" @dismissed="dismissCountDown2 = 0"
      @dismiss-count-down="countDownChanged">
      {{ alertMsgContent }}
    </b-alert>

    <div class="table-container mb-3" v-if="roles.length > 0">
      <b-table sticky-header="35em" :key="tableKey" class="mb-3" :items="permissionsFiltered" :fields="fields" :per-page="perPage"
        :current-page="currentPage" striped bordered hover>

        <template #cell(interface_name)="row">
          <div class="d-flex align-items-center">
            <span>{{ row.item.interface_name }}</span>
            <i class="bi bi-info-circle ml-2"
               v-b-tooltip.hover
               :title="row.item.ui_description"></i>
          </div>
        </template>

        <template v-slot:head()="data">
          <div v-if="data.label != 'Permission'" class="d-flex flex-column align-items-center">
            <span>{{ data.label }}</span>
            <div class="d-flex" v-if="!data.field.default">
              <b-button variant="link" @click="openEditModal(data)" :disabled="!canEdit">
                <i class="bi bi-pencil-square"></i>
              </b-button>
              <b-button v-if="!data.field.default" variant="link" @click="openDeleteModal(data)" :disabled="!canEdit">
                <i class="bi bi-trash text-danger"></i>
              </b-button>
            </div>
          </div>
        </template>

        <template v-for="role in roles" v-slot:[`cell(${role.groupRoleId})`]="row">
          <div class="d-flex flex-column align-items-center" :key="role.groupRoleId">
            <b-form-checkbox 
              class="d-flex justify-content-around" 
              :disabled="!canEdit || role.default"
              :checked="checkGroupedPermission(role.groupRoleId, row.item)"
              @change="handlePermissionChange($event, role.groupRoleId, row.item)" 
            />
          </div>
        </template>
      </b-table>
    </div>
    
    <div v-else class="text-center my-3">
      <b-alert show variant="info">
        <p>No roles created yet. Please create a new role to configure permissions.</p>
      </b-alert>
    </div>

    <div class="d-flex justify-content-end">
      <b-button class="mb-5" variant="outline-danger" :disabled="!canEdit || !permissionsChanged" @click="revertPermissions">Revert
        Changes</b-button>
      <b-button class="mb-5 mx-3" :disabled="!canEdit || !permissionsChanged" @click="savePermissions"
        style="background-color: #0093FF">Save Permissions</b-button>
    </div>

    <!-- Edit Role Modal -->
    <b-modal v-model="editModal" title="Edit Role Name">
      <input 
        type="text" 
        class="form-control" 
        id="editRoleInput" 
        v-model="selectedRoleName" 
        maxlength="30"
        :class="{ 'is-invalid': editRoleError.length > 0 }"
      >
      <div class="invalid-feedback">{{ editRoleError }}</div>
      <div slot="modal-footer" class="text-right">
        <b-button class="mx-2" variant="secondary" @click="closeEditModal">Cancel</b-button>
        <b-button class="mx-2" variant="primary" @click="editRoleName">Save</b-button>
      </div>
    </b-modal>

    <!-- Delete Role Confirmation Modal -->
    <b-modal v-model="deleteModal" title="Delete Role?">
      <p>Are you sure you want to delete role <b>{{ selectedRoleName }}</b>?</p>
      <p>Users assigned this role will be demoted to 'viewer' and might lose their current permissions.</p>
      <div slot="modal-footer" class="text-right">
        <b-button class="mx-2" variant="secondary" @click="closeDeleteModal">Cancel</b-button>
        <b-button class="mx-2" variant="danger" @click="confirmDeleteRole">Delete</b-button>
      </div>
    </b-modal>    
    <!-- Modal -->
    <b-modal
      v-model="showCreateRoleModal"
      title="Create new role"
      @hide="resetForm"
      hide-footer
    >
      <!-- Text input field -->
      <div class="form-group">
        <label for="newRoleName">Enter a name for the new role *</label>
        <input
          type="text"
          class="form-control"
          id="newRoleName"
          v-model="newRoleName"
          :class="{ 'is-invalid': newRoleError.length > 0 }"
        />
        <div class="invalid-feedback">{{ newRoleError }}</div>
      </div>

      <!-- Dropdown for copying permissions -->
      <div class="form-group">
        <label for="copyPermissions">Copy permissions from an existing role</label>
        <b-form-select
          id="copyPermissions"
          v-model="selectedRoleToCopy"
          :options="[{ value: 0, text: 'No role' }, ...roles.map(role => ({ value: role.permissionValue, text: role.name }))]"
          class="form-control"
        ></b-form-select>
      </div>

      <!-- Instructional text -->
      <p>For further adjustments of other permissions use the check-boxes in the table.</p>

      <!-- Buttons aligned to the right -->
      <div class="d-flex justify-content-end mt-3">
        <b-button variant="secondary" @click="showCreateRoleModal = false">Cancel</b-button>
        <b-button variant="primary" class="ml-2" @click="createNewRole">Create</b-button>
      </div>
    </b-modal>    
  </div>
</template>


<script>
import axios from 'axios';
import { BIconTelephonePlusFill } from 'bootstrap-vue';

export default {
  name: 'GroupsRolesTable',
  props: {
    organisationId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      roles: [],
      permissions: [],
      search: '',
      menu: '',
      alertMsgContent: "",
      dismissCountDown: 0,
      infoModal: false,
      permissionsChanged: false,
      role: '',
      editModal: false,
      selectedRoleName: '',
      selectedRoleId: '',
      deleteModal: false,
      roleToDelete: null,
      selectedPermissions: {},
      originalPermissions: {},
      lastSavedPermissions: {},
      tableKey: 0,
      newRoleName:'',
      showCreateRoleModal: false,
      roleOptions: "",
      selectedRoleToCopy: 0,
      newRoleError:"",
      editRoleError: '',
    };
  },
  computed: {
    permissionsFiltered() {
      if (!this.search.trim()) {
        return this.groupedPermissions;
      }
      const searchTerm = this.search.trim().toLowerCase();
      return this.groupedPermissions.map(group => {
        const filteredPermissions = group.permissions.filter(permission =>
          permission.key.toLowerCase().includes(searchTerm) ||
          permission.interface_name.toLowerCase().includes(searchTerm) ||
          (permission.ui_description && permission.ui_description.toLowerCase().includes(searchTerm))
        );
        return filteredPermissions.length > 0 ? { ...group, permissions: filteredPermissions } : null;
      }).filter(Boolean);
    },
    fields() {
      const fields = [{ key: 'interface_name', label: 'Permission', default: "default", stickyColumn: true }];
      this.roles.forEach(role => {
        fields.push({ key: role.groupRoleId, label: role.name, default: role.default });
      });
      return fields;
    },
    rows() {
      return this.permissionsFiltered.length;
    },
    calculatedPermissions() {
      const result = {};
      this.roles.forEach(role => {
        result[role.groupRoleId] = {};
        this.permissions.forEach(permission => {
          result[role.groupRoleId][permission.key] = this.checkPermission(role.groupRoleId, permission.key);
        });
      });
      return result;
    },
    customRoles() {
      return this.$accessControlStore.customRoles;
    },
  },
  watch: {
    search() {
      this.tableKey += 1;
    },
    roles: {
      handler() {
        this.$forceUpdate();
      },
      deep: true
    },
    permissions: {
      handler() {
        this.$forceUpdate();
      },
      deep: true
    }
  },
  mounted() {
    this.getPermissions().then(() => {
      this.groupPermissions();
      this.getRoles()
    });
  },
  methods: {
    getPermissionDescription(permissionKey) {
      const permission = this.permissions.find(p => p.key === permissionKey);
      return permission ? permission.description : 'No description available';
    },
    savePermissions() {
      if (!this.canEdit) return;
      
      const promises = [];
      Object.keys(this.selectedPermissions).forEach(roleId => {
        const permissionsArray = Object.keys(this.selectedPermissions[roleId]).filter(key => this.selectedPermissions[roleId][key]);
        const promise = axios.post(`${this.$address}api/groupRoles/editRole?access_token=${this.token}`, {
          permissionsArray,
          roleId,
        });
        promises.push(promise);
      });

      // Execute all promises
      Promise.all(promises)
        .then(responses => {
          this.alertMsgContent = "The changes have been saved successfully.";
          this.dismissCountDown = 5;
          this.permissionsChanged = false;
          // Update lastSavedPermissions with the current state
          this.lastSavedPermissions = JSON.parse(JSON.stringify(this.selectedPermissions));
          this.getRoles();
        })
        .catch(error => {
          console.error('Error saving permissions:', error);
        });
    },
    revertPermissions() {
      if (!this.canEdit) return;
      
      // Revert to the last saved state (lastSavedPermissions)
      this.selectedPermissions = JSON.parse(JSON.stringify(this.lastSavedPermissions));
      this.permissionsChanged = false;
      this.tableKey++;
    },
    handlePermissionChange(event, roleId, groupedPermission) {
      if (!this.canEdit) return;
      
      if (!this.selectedPermissions[roleId]) {
        this.$set(this.selectedPermissions, roleId, {});
      }

      const isChecked = event;

      groupedPermission.permissions.forEach(permission => {
        if (isChecked) {
          this.$set(this.selectedPermissions[roleId], permission.key, true);
        } else {
          this.$delete(this.selectedPermissions[roleId], permission.key);
        }
      });

      this.permissionsChanged = !this.arePermissionsEqual(this.selectedPermissions, this.lastSavedPermissions);
    },
    checkGroupedPermission(roleId, groupedPermission) {
      return groupedPermission.permissions.every(permission => 
        this.checkPermission(roleId, permission.key)
      );
    },
    arePermissionsEqual(permissions1, permissions2) {
      const roles1 = Object.keys(permissions1);
      const roles2 = Object.keys(permissions2);

      if (roles1.length !== roles2.length) return false;

      for (const roleId of roles1) {
        if (!permissions2[roleId]) return false;

        const perms1 = permissions1[roleId];
        const perms2 = permissions2[roleId];

        const keys1 = Object.keys(perms1);
        const keys2 = Object.keys(perms2);

        if (keys1.length !== keys2.length) return false;

        for (const key of keys1) {
          if (perms1[key] !== perms2[key]) return false;
        }
      }

      return true;
    },
    createNewRole() {
      if (!this.canEdit) return;
      
      this.newRoleError = '';

      if (this.newRoleName.trim() === '') {
        this.newRoleError = "Role name cannot be empty";
        return;
      }

      const roleNameExists = this.roles.some(role => 
        role.name.toLowerCase() === this.newRoleName.trim().toLowerCase()
      );

      if (roleNameExists) {
        this.newRoleError = "A role with this name already exists";
        return;
      }

      const requestData = {
        name: this.newRoleName.trim(),
        permissionsArray: [],
        groupID: this.organisationId,
        value: this.selectedRoleToCopy.toString()
      };

      axios.post(this.$address + 'api/groupRoles/createNewRole?access_token=' + this.token, requestData)
        .then(response => {
          this.newRoleName = '';
          this.alertMsgContent = "The role has been successfully created";
          this.dismissCountDown = 5;
          this.showCreateRoleModal = false;
          this.getRoles();
        })
        .catch(error => {
          console.error('Error creating role:', error);
          this.newRoleError = "An error occurred while creating the role";
        });
    },
    getRoles() {
      axios.get(this.$address + 'api/groupRoles/getRoles?access_token=' + this.token, {
        params: {
          organizationId: this.organisationId
        },
      })
        .then(response => {
          this.roles = response.data;
          this.roles.forEach(role => {
            this.$set(this.selectedPermissions, role.groupRoleId, {});
            this.$set(this.lastSavedPermissions, role.groupRoleId, {}); // Initialize lastSavedPermissions

            const permissionValue = BigInt(role.permissionValue);

            this.permissions.forEach((permission) => {
              if ((permissionValue & BigInt(permission.value)) !== BigInt(0)) {
                this.$set(this.selectedPermissions[role.groupRoleId], permission.key, true);
                this.$set(this.lastSavedPermissions[role.groupRoleId], permission.key, true);
              }
            });
          });
        })
        .catch(error => {
          console.error('Error fetching roles:', error);
        });
    },
    getPermissions() {
      return axios.get(this.$address + 'api/groupRoles/getPermissions?access_token=' + this.token)
        .then(response => {
          this.permissions = Object.keys(response.data.permissions).map(key => ({
            key: key,
            value: response.data.permissions[key].value,
            description: response.data.permissions[key].description,
            interface_name: response.data.permissions[key].interface_name || key,
            ui_description: response.data.permissions[key].ui_description || response.data.permissions[key].description
          }));
          console.log("Permissions: ", this.permissions);
        })
        .catch(error => {
          console.error('Error fetching permissions:', error);
        });
    },
    groupPermissions() {
      const grouped = {};
      this.permissions.forEach(permission => {
        if (!grouped[permission.interface_name]) {
          grouped[permission.interface_name] = {
            interface_name: permission.interface_name,
            ui_description: permission.ui_description,
            permissions: []
          };
        }
        grouped[permission.interface_name].permissions.push(permission);
      });
      this.groupedPermissions = Object.values(grouped);
    },
    checkPermission(roleId, permissionKey) {
      const role = this.roles.find(r => r.groupRoleId === roleId);
      if (!role) return false;

      const permissionValue = BigInt(role.permissionValue);
      const permissionIndex = this.permissions.find(p => p.key === permissionKey);
      if (permissionIndex === -1) {
        return false;
      }
      const hasPermission = (permissionValue & BigInt(permissionIndex.value))!== BigInt(0);
      console.log("The comparastion of " +role.permissionValue +" and "+ permissionIndex.value + " is "+ hasPermission)
      return hasPermission;
    },
    openEditModal(data) {
      if (!this.canEdit) return;
      
      this.selectedRoleName = data.label;
      this.selectedRoleId = data.column;
      this.editRoleError = '';
      this.editModal = true;
    },
    closeEditModal() {
      this.selectedRoleName = '';
      this.selectedRoleId = '';
      this.editRoleError = '';
      this.editModal = false;
    },
    editRoleName() {
      if (!this.canEdit) return;
      
      this.editRoleError = '';

      if (this.selectedRoleName.trim() === '') {
        this.editRoleError = "Role name cannot be empty";
        return;
      }

      const roleNameExists = this.roles.some(role => 
        role.groupRoleId !== this.selectedRoleId && 
        role.name.toLowerCase() === this.selectedRoleName.trim().toLowerCase()
      );

      if (roleNameExists) {
        this.editRoleError = "A role with this name already exists";
        return;
      }

      axios.post(this.$address + 'api/groupRoles/editRoleName?access_token=' + this.token, {
        roleId: this.selectedRoleId,
        name: this.selectedRoleName.trim(),
      })
        .then(response => {
          this.alertMsgContent = "The role name has been successfully edited";
          this.dismissCountDown = 5;
          this.getRoles();
          this.closeEditModal();
        })
        .catch(error => {
          console.error('Error editing role name:', error);
          this.editRoleError = "An error occurred while editing the role name";
        });
    },
    openDeleteModal(data) {
      if (!this.canEdit) return;
      
      this.selectedRoleName = data.label;
      this.selectedRoleId = data.column;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.selectedRoleName = '';
      this.selectedRoleId = '';
      this.deleteModal = false;
    },
    confirmDeleteRole() {
      if (!this.canEdit) return;
      
      axios.delete(`${this.$address}api/groupRoles/${this.selectedRoleId}?access_token=${this.token}`
      )
        .then(response => {
          this.alertMsgContent = "The role has been successfully deleted";
          this.dismissCountDown = 5;
          this.getRoles();
        })
        .catch(error => {
          console.error('Error deleting role:', error);
        });
      this.closeDeleteModal();
    },
  },
};
</script>

<style scoped>
h2.title {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

::v-deep .table>tbody>tr>td {
  vertical-align: middle !important;
}

::v-deep .table thead th {
  vertical-align: middle !important;
}
</style>