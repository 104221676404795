<template>
    <b-container fluid>
        <b-row align-v="center">
            <!-- Search section -->
            <b-col cols="12" md="6" lg="8" class="mt-3">
                <b-input-group>
                    <b-form-input type="search" placeholder="Search by organization, email or user type" v-model="search"/>
                    <b-input-group-append>
                        <b-input-group-text>
                            <i class="bi bi-search"></i>
                        </b-input-group-text>
                    </b-input-group-append>   
                </b-input-group>
                <!-- User creation section -->
                <b-row v-if="canCreateUser">
                    <!-- Email -->
                    <b-col cols="12" :lg="usertype === 'admin' ? '3' : '4'" class="mt-3">
                        <input type="email" class="form-control" :class="{ 'is-invalid': !form.validEmail }" id="emailInput" placeholder="email" v-model="email">
                        <div class="invalid-feedback">Email is required</div>
                    </b-col>
                    <!-- Usertype -->
                    <b-col cols="12" md="6" :lg="usertype === 'admin' ? '3' : '4'" class="mt-3">
                        <select id="dropdown" class="browser-default custom-select" :class="{ 'is-invalid': !form.validRole }" v-model="inputUserType">
                            <option v-for="role in organizationRoles" :key="role.value" :value="role">
                            {{ role.name }}
                        </option>                            
                        </select>
                        <div class="invalid-feedback">A usertype is required</div>
                    </b-col>
                    <!-- Organization -->
                    <b-col cols="12" lg="6" class="mt-3" v-if="usertype === 'admin'">
                        <v-select
                            class="custom-selector"
                            :class="{ 'is-invalid err-msg': !form.validOrg }"
                            v-model="organisation"
                            :options="domains"
                            :reduce="name => name.id"
                            label="name"
                        ></v-select>
                        <div class="invalid-feedback">An organisation is required</div>
                    </b-col>
                    <!-- Create button -->
                    <b-col cols="12" :lg="usertype === 'admin' ? '12' : '4'" class="mt-3">
                        <b-button block variant="outline-info" v-on:click="CreateNewUser">Create</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <!-- Drag & drop -->
            <b-col cols="12" md="6" lg="4" class="mt-3" v-if="canCreateUser">
                <dragAndDropUsers :token="token" :Orga="organisationID" @listNewUser="addUserToList" id="drag" />
            </b-col>
        </b-row>
        <!-- Users table and drag & drop -->
        <b-row class="mt-3">
            <!-- Table -->
            <b-col cols="12">
                <b-alert
                    :show="dismissCountDown"
                    dismissible
                    variant="success"
                    @dismissed="dismissCountDown = 0"
                    @dismiss-count-down="countDownChanged"
                >
                    {{ alertMsgContent }}
                </b-alert>
                <b-table
                    id="users-table"
                    @filtered="onFiltered"
                    :filter="search"
                    :items="users"
                    :fields="tableFields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :busy="isBusy"
                    striped
                    bordered
                    stacked="md"
                    show-empty
                    :sort-by="sortBy"
                    :sort-desc="true"
                >
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle mr-2" />
                            <strong>Loading users...</strong>
                        </div>
                    </template>
                    <template #cell(usertype)="row">
                        <div class="d-flex align-items-center justify-content-between">
                            <!-- Proper display of roles -->
                            <template v-if="row.item.organizationRoles">{{row.item.organizationRoles.name  }}</template>
                            <template v-else>No roles assigned</template>
                            <!-- Edit usertype button -->
                            <template>
                                <b-button variant="link" @click="openEditUserType(row.item)" v-if="canEditUser">
                                    <i class="bi bi-pencil-square"></i>
                                </b-button>
                            </template>
                        </div>
                    </template>
                    <template  v-if="usertype === 'admin'" #cell(organizationName)="row">
                        <p class="mb-0">{{ row.item.organizationName }}</p>
                        <span class="text-muted small">{{ row.item.organisationID }}</span>
                    </template>
                    <template #cell(creationTime)="row">
                        {{ new Date(row.item.creationTime).toLocaleString('en-GB') }}
                    </template>                  
                    <template #cell(password)="row">
                        <div class="text-center">
                            <b-button variant="outline-dark" @click="resetPassword(row.item)">
                            Reset
                        </b-button>
                        </div>
                    </template>
                    <template v-if="usertype === 'admin'" #cell(loginDate)="row">
                        {{ row.item.loginDate ? new Date(row.item.loginDate).toLocaleString('en-GB') : '--'}}
                    </template>
                    <template #cell(delete)="row">
                        <div class="text-center">
                            <b-button v-if="usertype!='admin' && row.item.usertype =='admin'" variant="dark" disabled>
                                <i class="bi bi-trash-fill"></i>
                            </b-button>
                            <b-button
                                v-else-if="(usertype === 'manager') && (row.item.usertype === 'orgAdmin' || row.item.usertype === 'demoAdmin')"
                                variant="dark"
                                disabled
                            >
                                <i class="bi bi-trash-fill"></i>
                            </b-button>
                            <b-button v-else @click="deleteUser(row.item.id)" variant="dark">
                                <i class="bi bi-trash-fill"></i>
                            </b-button>
                        </div>
                    </template>
                </b-table>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="users-table"/>
            </b-col>
        </b-row>
        <!-- Modals -->
        <stack-modal :show="showWrongEmailModal" title="Error while creating the user" :saveButton=saveButtonDefaults @close="showWrongEmailModal=false">
            Please make sure you provided all required information!
        </stack-modal>
        <stack-modal :show="showExistingEmailModal" title="Error while creating the user" :saveButton=saveButtonDefaults @close="showExistingEmailModal=false">
            This email address is already registered to another organization on this server. (It might be in use for a demo account organization). 
            Please contact the just entered user or get in touch with <a href="mailto:contact@ar-experts.de">contact@ar-experts.de</a> for help.
        </stack-modal>
        <stack-modal :show="showExistingEmailInOrganizationModal" title="Error while creating the user" :saveButton=saveButtonDefaults @close="showExistingEmailInOrganizationModal=false">
            This email address is already registered to your organization.
        </stack-modal>
        <stack-modal :show="cantRemoveAdmin" title="Error" :saveButton=cantRemoveSave :cancelButton=cantRemoveCancel @close="cantRemoveAdmin=false">
            Admin cannot be removed
        </stack-modal>
        <!-- Edit usertypes -->
        <stack-modal v-model="userToEdit" :show="isEditingUserType" title="Edit user type" @close="isEditingUserType=false" @save="handleRoleChange(inputUserType)">
            <div class="row">
                <div class="col-sm-12">
                    <label for="dropdown2" v-if="userToEdit">Select the new user type for: {{userToEdit.email}}</label>
                    <select id="dropdown2" class="browser-default custom-select" v-model="inputUserType">
                        <!-- If user is admin -->
                        <option v-for="role in organizationRoles" :key="role.value" :value="role">
                            {{ role.name }}
                        </option>
                    </select>
                </div>
            </div>
        </stack-modal>
    </b-container>
</template>

<script>
import axios from 'axios'
import "bootstrap-vue/dist/bootstrap-vue.css";
import StackModal from '@innologica/vue-stackable-modal'
import dragAndDropUsers from '@/components/Components/dragAndDropUsers'
    export default {
        components: {
            StackModal,
            "dragAndDropUsers" : dragAndDropUsers
        },
        props:{
            token:String,
            usertype:String,
            organisationID:String
            //id:String
        },
        data(){
            return{
                address:"to be specified", //loopback access point
                alertMsgContent: "",
                showWrongEmailModal: false,
                showExistingEmailModal: false,
                showExistingEmailInOrganizationModal:false,
                cantRemoveAdmin:false,
                //posts:[],
                name:"",
                dismissCountDown: 0,
                dismissSecs: 5,
                email:"",
                search:"",
                description:"",
                username:"",
                organizationRoles:[],
                password:"",
                isBusy:false,
                rows:1,
                organisation:"",
                order: "default",
                form: {
                    validEmail: true,
                    validRole: true,
                    validOrg: true
                },
                fields:[{username:'username'}, {usertype:'usertype'}, {email:'email'},{organisationID:'organisationID'}, {password:'password'},{action:'action'}],
                domains:[{username:'name'}, {usertype:'domain'},{id:'org id'}, {workplaces:'workplaces'}, {addNew:'addNew'}, {remove:'remove'}],
                //columns: ['username', 'usertype', 'email', 'organisationID', 'password', 'action'],
                //componentKey: 0,
                //toExport: '',
                inputUserType:"",
                orgs:[{id:'id',name:'name'}],
                saveButtonDefaults:{
                    title: 'Ok',
                    visible: false,
                    btnClass: {'btn btn-primary': true}
                },
                cantRemoveSave:{
                    title: 'Ok1',
                    visible: false,
                    btnClass: {'btn btn-secondary': true,
                    onClick:"return false"}
                },
                cantRemoveCancel:{
                    title: 'Ok',
                    visible: true,
                    btnClass: {'btn btn-secondary': true}
                },
                resetPass: false,
                band: 0,
                searchStat:0,
                /* bandChanging:false, */
                users: [],
                tableFields: [
                    { key: 'email', label: 'E-mail', sortable: true },
                    { key: 'usertype', label: 'User Roles', sortable: true },
                    { key: 'organizationName', label: 'Organization', sortable: true },
                    { key: 'creationTime', label: 'Creation time', sortable: true },
                ],
                currentPage: 1,
                perPage: 15,
                isEditingUserType: false,
                userToEdit: undefined,
                sortBy: 'creationTime',
                sortDesc: false,
            }
        },
        computed: {
            canCreateUser() {
                return this.$accessControlStore.permissions.CREATE_USER;
            },
            canEditUser(){
                return this.$accessControlStore.permissions.EDIT_USER;
            },
            canDeleteUser() {
                return this.$accessControlStore.permissions.DELETE_USER;
            }
        },
        beforeMount() {
            if (this.usertype === 'admin') {
            this.tableFields.push({ key: 'loginDate', label: 'Last Active', sortable: true},);
            }
        },
        mounted() {
            if (this.canEditUser) {
                this.tableFields.push({ key: 'password', label: 'Reset Password'});
            }
            if (this.canDeleteUser) {
                this.tableFields.push({ key: 'delete', label: 'Delete' });       
            }
            this.getUsers();
            
            console.log(this.rows)
            axios.get(this.$address + 'api/organizationRoles/getRoles?access_token=' + this.token, {
                params: {
                organizationId:this.organisationID,

                },
      }).then(response =>{
        this.organizationRoles = response.data;
      });
            axios.get(this.$address+"api/Organisations?access_token="+this.token, {
                params: {
                    filter: { order: "name ASC"}
                }
            }).then(response => {
                this.domains = response.data;
            });
        },
        methods: {
            validateForm(org = '') {
                if (!org) org = '';
                this.email = this.email.trim();
                (this.email.length > 0) ? this.form.validEmail = true : this.form.validEmail = false;
                (org.length > 0) ? this.form.validOrg = true : this.form.validOrg = false;
            },
            CreateNewUser() {
                const org =(this.usertype == "admin")?this.organisation:this.organisationID;
                const resultOrganization = this.domains.filter(obj => obj.id === org);
                const organizationName = resultOrganization[0]?.name
                this.validateForm(org);
                if (!this.form.validEmail || !this.form.validRole || !this.form.validOrg) return;
                const vals={
                    "usertype":"orgAdmin",
                    "email":this.email,
                    "password":this.password != null && this.password != "" && this.usertype == "admin"? this.password: Math.round(Math.random()*8999 + 1000).toString(10),
                    "organisationID":org,
                    organizationRoleId: this.inputUserType.organizationRoleId
                }
                console.log(vals);
                if(this.email!=""){
                    axios.post(this.$address+'api/UserAccounts?access_token='+this.token, vals).then(response=>{
                        const { data } = response;
                        vals.id = data.id;
                        vals.creationTime = new Date();
                        vals.organizationName = organizationName;
                        vals.organisationID= data.organisationID
                        this.users.push(vals);
                        this.clearInput();
                        this.alertMsgContent = "The user has been created successfully.";
                        this.dismissCountDown = this.dismissSecs;
                    }, 
                    (error) => { 
                        if(error.response.data.error.statusCode===502){
                            this.showExistingEmailModal=true;
                            this.clearInput();
                        }else if(error.response.data.error.statusCode==501){
                            this.showExistingEmailInOrganizationModal=true;
                            this.clearInput();
                        }else{
                            this.showWrongEmailModal=true;
                            this.clearInput();                          
                        }

                    })
                }
                else
                    this.showWrongEmailModal=true;
            },            
            deleteUser(id) {
                this.$bvModal.msgBoxConfirm('Are you sure you want to delete this user?', {
                    title: 'Please Confirm',
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelTitle: "NO",
                }).then(async (confirmDelete) => {
                    if (!confirmDelete) return;
                    const { data } = await axios.get(`${this.$address}api/UserAccounts/${id}?&access_token=${this.token}`);
                    if (data.usertype === 'admin') {
                        console.log('cannot remove admin');
                        this.cantRemoveAdmin = true;
                    } else {
                        console.log("removing user");
                        await axios.delete(`${this.$address}api/UserAccounts/${id}?access_token=${this.token}`);
                        const idx = this.users.findIndex((item) => item.id === id);
                        this.users.splice(idx, 1);
                    }
                });
            },
            clearInput(){
                this.username=''
                this.password=''
                this.email=''
                this.organisation=''
            },
            resetPassword(user){             
                // The function on the backend receives an email not an ID
                const data={
                    userID:user.email,
                }
                this.resetPass = true;
                axios.post(this.$address+'api/UserAccounts/resetPasswordRequest?access_token='+this.token, data).then(response=>{
                    this.alertMsgContent = "A password recovery email has been sent to the user.";
                    this.dismissCountDown = this.dismissSecs;
                    this.refreshOrgList();
                });
            },
            isSearched(mail){
                return this.search == mail || mail.includes(this.search);
            },
            refreshOrgList(){
                axios.get(this.$address+"api/Organisations?access_token="+this.token)
                .then(response => {
                    this.domains=response.data;
                });
            },
            getOrg(org){
                for (let x = 0; x < this.domains.length; x++){
                    if(this.domains[x].id == org) return(this.domains[x]);
                }
                return("-");
            },
            async addUserToList(usr){
                this.fields.push(usr);
                await this.getUsers();
                this.users.sort((a, b) => new Date(a.creationTime) - new Date(b.creationTime));
                await this.$nextTick();
                this.sortBy = 'creationTime';
            },
            getUsers(){
                this.isBusy = true;
                let params = {
                    index:0,
                    order:"ASCD",
                    filter:{
                        "organization":this.organisationID,
                    }
                }
                // If the user is admin it will bring the users of all organizations
                if(this.usertype === 'admin'){
                    params.filter = {}
                }

                axios.get(this.$address+'api/UserAccounts/usersList?access_token='+this.token, {"params":params}).then((res)=>{
                    this.fields = res.data
                    this.users = res.data
                    this.rows= this.users.length
                    this.isBusy = false;
                    console.log(this.users)
                },
                (err)=>{
                    this.isBusy = false;
                    console.log(err);
                });
            },
            // Shows the modal to edit usertypes
            openEditUserType(user){
                this.isEditingUserType = true;
                this.userToEdit = user;
            },
            // Edits the usertypes
            editUserType(newUserType = '') {
                const vals = {
                    id: this.userToEdit.id,
                    usertype: newUserType
                };
                let demoChanged = false;
                if (this.usertype !== 'admin' && newUserType === 'admin') {
                    this.showWrongEmailModal = true;
                    return;
                }
                if (this.userToEdit.isDemo && newUserType !== 'werker') {
                    vals['isDemo'] = false;
                    demoChanged = true;
                }
                axios.patch(`${this.$address}api/UserAccounts/${vals.id}?access_token=${this.token}`, vals)
                    .then(() => {
                        this.userToEdit.usertype = newUserType;
                        this.isEditingUserType = false;
                    })
                    .catch((error) => {   
                        this.showWrongEmailModal = true;
                    });
            },
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown;
            },
            onFiltered(filteredItems) {
                this.rows = filteredItems.length
                this.currentPage = 1
            },
            handleRoleChange(role) {
                axios.patch(`${this.$address}api/UserAccounts/${this.userToEdit.id}?access_token=${this.token}`, {
                    organizationRoleId: role.organizationRoleId
                })
                .then(response => {
                    if(this.userToEdit.organizationRoles)
                        this.userToEdit.organizationRoles.name = role.name;
                    else{
                        this.userToEdit.organizationRoles = {name:role.name}
                    }
                    this.isEditingUserType = false;
                  console.log('Role updated successfully:', response.data);
                })
                .catch(error => {
                  console.error('Error updating role:', error);
                });

            }}
    }
</script>

<style lang="css" scoped>
button:disabled {
    background-color: #D8DCDF;
    border-color: #D8DCDF;
}
.btn.disabled, .btn:disabled {
    opacity: 1 !important;
}
.custom-selector {
    --vs-line-height: 1.6;
}
.err-msg {
    --vs-controls-color: #dc3545;
    --vs-border-color: #dc3545;
}
::v-deep .table > tbody > tr > td {
vertical-align: middle !important;
}
::v-deep .table thead th {
vertical-align: middle !important;
}
</style>